import React from "react";


const Game = () => {

    return (
        <section id="game" className={"game"}>
            <div className={"contentInfoBlock"}>

                <br/>
                <h2>Aquatrion's Essence Game</h2>
                <br/>
                <span style={{
                    textAlign: "left",
                    fontSize: "1.5rem",
                }}>
                <p >Welcome to the enchanting realm of Aquatrion's Essence, an immersive GameFi adventure where tranquil
                    fishing meets the boundless possibilities of blockchain technology. Here, every ripple in the water
                    tells a story, every catch holds a treasure, and your journey could just begin…</p>
                <br/>
                <h4>Discover a World Brimming with Magic and Mystery</h4>
                <br/>
                <p>Dive into a fantasy world that keeps its mysteries, waiting to be discovered. In Aquatrion's Essence,
                    you're not just casting a line; you're casting yourself into a saga where you can become a legend,
                    make a fortune, or just create a tale as unique as your own.</p>
                <br/>
                <h4>Earn as You Play, Play as You Earn</h4>
                <br/>
                <p>Your passion for gaming could now turn into a profitable journey. With our dual-token and
                    self-balancing system, your in-game assets could become of real-world value. Imagine trading your
                    caught trophy with fellow anglers or forging them into mystical NFTs that grow in worth over time.
                    Feel the thrill of the chase with quests that take you from sun-dappled shores to the darkest lands,
                    all while you enhance your character, honing their skills and crafting a legend to be
                    remembered…</p>
                <br/>
                <p>Will you dare to challenge this world, where the stakes are as high as the waves, and victory is as
                    sweet as the ocean breeze?</p>
                <br/>
                <p>Step into the world of Aquatrion's Essence, where every catch counts, every story is sung, and every
                    moment on the water is a wave towards wonder. Cast off from the ordinary and set sail for the
                    extraordinary — your legend awaits. Your progress is your profit… your life in your hands.</p>
                </span>
            </div>
        </section>
)
}

export default Game;
