

const FooterOwn = () => {
    return (
        <section id="contact">

            <div className={"contentInfoBlock"}>

                <br/>
                <h2>Get in Touch with Us</h2>
                <br/>
                <div id="ff-compose"></div>

                <div style={{
                    height: "300px",
                    width: "100%",
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                    alignItems: "center",
                    textAlign: "center",
                }}>
                    <hr style={{
                        width: "100%",
                        border: "1px solid white",
                    }}/>
                    <a style={{textDecoration: "none", color: "white",}} href={"PrivacyPolicy.pdf"}> Privacy Policy </a>
                    <a style={{textDecoration: "none", color: "white", marginBottom: "50px"}} href={"Terms.pdf"}> Terms of Service</a>
                    <span>&copy; Copyright 2024, Aquatrion's Essence</span>
                </div>
            </div>


        </section>

    )
}

export default FooterOwn;
