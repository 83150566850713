import React, {useEffect} from "react";
import logo from './logo.svg';
import taggleMenuIcon from './images/taggleMenuIcon.svg';
import './App.css';
import Home from "./pages/home";
import Game from "./pages/game";
import Team from "./pages/team";
import Footer from "./pages/footer";
import FooterOwn from "./pages/footer";
import MagicTail from "./utils/magicTail";
import avalanchLogo from "./images/avalogo.svg";


function App() {

    const [taggle, setTaggle] = React.useState(false);



    useEffect(() => {
        MagicTail();

    }, []);

    const taggleMenu = (e:any) => {
        setTaggle(!taggle);
    }


  return (
     <>
      <div className="App">
          <header className={taggle ? "open-menu" : ""}>
              <ul>
                  <li>
                      <img onClick={(e) => taggleMenu(e)} className={"toggleIcon"} src={taggleMenuIcon} width={60}
                           height={60}/>
                      <div className={"logoGameText"}></div>
                  </li>
                  <li><a href="#home" onClick={() => setTaggle(false)}>HOME</a></li>
                  <li><a href="#game" onClick={() => setTaggle(false)}>GAME</a></li>
                  <li><a href="#team" onClick={() => setTaggle(false)}>TEAM</a></li>
                  <li><a href="#contact" onClick={() => setTaggle(false)}>CONTACT</a></li>
                  <li><a rel={"noreferrer"} target={"_blank"} onClick={() => setTaggle(false)}
                         href="https://fishingquest.notion.site/FAQ-f899ad9e739d4f0399e0fb3eb66993c0">FAQ</a>
                  </li>
                  <li><a rel={"noreferrer"} target={"_blank"} onClick={() => setTaggle(false)}
                         href="https://fishingquest.notion.site/Whitepaper-8ad35194d4ee4695a60afa6c5cb66dfd">WHITEPAPER</a>
                  </li>
                  <li className={"discord-link"}></li>
                  <a rel={"noreferrer"} target={"_blank"} onClick={() => setTaggle(false)}
                     href="https://twitter.com/aquatrion">
                      <li className={"x-link"}></li>
                  </a>
                  <a rel={"noreferrer"} target={"_blank"} onClick={() => setTaggle(false)}
                     href="https://t.me/Aquatrion">
                      <li className={"tg-link"}></li>
                  </a>
                  <a style={{
                      backgroundColor: "white",
                      width: "24px",
                      height: "24px",
                      borderRadius: "50%"}}
                      rel={"noreferrer"} target={"_blank"}
                      onClick={() => setTaggle(false)  }
                      href="https://github.com/Aquatrions-essence">
                        <li className={"gh-link"}></li>
                    </a>


              </ul>
          </header>
          <article>
              <Home/>
              <Game/>
              <Team/>
              <Footer/>
          </article>


      </div>

     </>
  );
}

export default App;
