import React from "react";
import avalanchLogo from "../images/avalogo.svg";
import Footer from "./footer";
const myVideo = require('../images/video.mp4')
const Home = () => {

    const handlePlay = () => {
        window.location.href = "#contact";
    }

    return (

        <section id="home" className={"home"}>


            <div className={"videoTend"}>

                <video autoPlay muted loop className="myVideo">
                    <source src={myVideo} type="video/mp4"/>
                </video>
            </div>
            <div className={"videoTend"}>
            </div>
            <canvas className={"magic-tail"} id="magic-tail"></canvas>
            <div className={"content"}>

                <div className={"logoGameTextMain"}>
                </div>
                <button className={"PlayButton"} onClick={handlePlay}>PLAY</button>
                <div className={"contentInfoBlock"}>

                    <h1>Welcome to the enchanting realm of Aquatrion's Essence</h1>
                    <p style={{
                        fontSize: "1.5rem",
                    }}>An immersive GameFi adventure where tranquil
                        fishing meets the boundless possibilities of blockchain technology.</p>
                    <br/>
                    <div className={"screenCards"}>
                        <div className={"FishingScreen card "}>
                            <div className={"cardTitle"}>
                                FISHING
                            </div>
                        </div>
                        <div className={"QuestScreen card"}>
                            <div className={"cardTitle"}>
                                ADVENTURE
                            </div>
                        </div>
                        <div className={"InventoryScreen card"}>
                            <div className={"cardTitle"}>
                                INVENTORY
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default Home;
