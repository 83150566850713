import React from "react";
import Footer from "./footer";


const Team = () => {

    return (
        <section id="team" className={"team"}>

            <div className={"contentInfoBlock"}>
                <br/>
                <h2>Meet the Team Behind Aquatrion's Essence</h2>
                <br/>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    flexGrow: 1,

                }}>
                    <div style={{
                        maxWidth: "49%",
                        minWidth: "400px",
                        textAlign: "left",
                        flexGrow: 1,
                        fontSize: "1.5rem",
                        marginTop: "50px",
                    }} >

                        <h4>Vitaliy (Tokenomic, Game Design, ML, Math models)</h4>
                        <br/>

                        Drawing on over 15 years in the Merchant Marine Industry, including the last
                        six as a top officer, I've developed a unique perspective that deeply enriches our
                        GameFi project, Aquatrion's Essence. My expertise in complex mathematical
                        models and calculations has been instrumental in creating sophisticated game
                        strategies. This unusual background offers a broad vantage point for addressing
                        challenges, leveraging my experience in crypto to not only analyze but also earn
                        profit from the market. Actively engaging with and applying AI and Machine
                        Learning, I'm elevating our gaming strategies and user experience to new heights.
                        Committed to advancing in the IT and data science domains, I utilize my expanding
                        skill set to refine our gaming experience. As a seasoned gamer and developer, I
                        infuse trust, expertise, and innovative insights into our venture, promising an
                        immersive journey through the untapped depths of blockchain gaming.
                    </div>
                    <div style={{
                        maxWidth: "49%",
                        minWidth: "400px",
                        textAlign: "left",
                        fontSize: "1.5rem",
                        marginTop: "50px",
                    }}>
                        <h4>Aleks (Arch, Development, Game Design, Smart Contracts)</h4>
                        <br/>
                        Beginning my IT career in Rocket Science, I spent five years honing IT skills
                        and tackling complex calculations. This journey led to a seven-year deep dive into
                        Finance Technologies, exploring fintech's intricacies and broadening my expertise.
                        My path then veered into Programmatic Advertising Tech for three years, where I
                        analyzed Big Data and optimized targeted advertising delivery. Now, deeply
                        involved in Game Development & Web3, I leverage my comprehensive knowledge,
                        including active engagement and advancement in AI and Machine Learning, to drive
                        the success of our GameFi project, Aquatrion's Essence. This trajectory underscores
                        not just growth but an enrichment of expertise, establishing me as a seasoned IT
                        professional at the vanguard of gaming technology evolution.
                    </div>

                </div>

            </div>

        </section>

)
}

export default Team;
